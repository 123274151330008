.brand {
  font-size: 2.5em;
}

a {
  color: #0000ee;
}

.navbar a {
  color: black;
  text-decoration: none;
}

.navbar {
  text-align: center;
}

.flex-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 1em;
}

.main {
  margin: 0 auto;
  max-width: 800px;
  padding: 2em;
}

.main img {
  max-width: 100%;
}

.narrow-content {
  max-width: 400px;
  margin: 0 auto;
}

.medium-content {
  max-width: 600px;
  margin: 0 auto;
}

.zine {
  text-align: center;
}

.zine img {
  max-width: 400px;
}
